.vue-flow__controls {
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.08);
}

.vue-flow__controls-button {
  background: #fefefe;
  border: none;
  border-bottom: 1px solid #eee;
  box-sizing: content-box;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  cursor: pointer;
  user-select: none;
  padding: 5px;
}

.vue-flow__controls-button svg {
  width: 100%;
  max-width: 12px;
  max-height: 12px;
}

.vue-flow__controls-button:hover {
  background: #f4f4f4;
}


.vue-flow__controls-button:disabled {
  pointer-events: none;
}

.vue-flow__controls-button:disabled svg {
  fill-opacity: 0.4;
}
